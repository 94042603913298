<template>
  <div class="grouplist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">所有账号客户资源汇总</span>
      <download-excel
        class="export-excel-wrapper"
        v-if="tabledata && tabledata.length != 0"
        :data="tabledata"
        :fields="json_fields"
        name="所有账号客户资源汇总.xls"
      >
        <el-button type="text" v-if="tabledata && tabledata.length != 0"
          >导出excel表格</el-button
        >
      </download-excel>
    </div>

    <div class="content">
      <div class="maincontent">
        <span>销售：</span>
        <el-select
          size="small"
          v-model="curInviter"
          filterable
          placeholder="请选择销售名称"
          @change="changeClick"
        >
          <el-option
            v-for="item in inviters"
            :key="item.UserId"
            :label="item.Username"
            :value="item.UserId"
          >
          </el-option>
        </el-select>
      </div>
      <com-table
        class="grouplist"
        :headercellstyle="headercellstyle"
        :columns="channelMainResrouce"
        :pagination="pagination"
        tableheight="calc(100vh - 300px)"
        :data="tabledata"
      >
      </com-table>
    </div>
    <edite-name
      title="关联ID"
      :row="row"
      :showeditename="showeditename"
      @closeeditename="closeeditename"
    />
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import { channelMainResrouce } from "@/utils/tableList";
import EditeName from "./Models/EditeName";
import formatdate from "@/utils/format";
import { IpRegionMap, ProductTypeMap, LineStatusMap } from "@/utils/constants";

export default {
  name: "channelMainResrouce",
  data() {
    return {
      channelMainResrouce,
      json_fields: {
        无觅手机号: "PhoneNumber",
        无觅ID: "UserId",
        资源类型: "ResourceType",
        资源ID: "ResourceId",
        产品: {
          callback: (value) => {
            return ProductTypeMap[value.ProductId] || value.ProductId;
          },
        },
        地区: {
          callback: (value) => {
            return IpRegionMap[value.RegionId] || value.RegionId;
          },
        },
        IP: {
          callback: (value) => {
            return value.Ip || "-";
          },
        },
        折扣: {
          callback: (value) => {
            return value.Discount || "1.0";
          },
        },
        资源创建时间: {
          callback: (value) => {
            return formatdate(value.CreateTime, "YYYY-MM-DD");
          },
        },
        资源到期时间: {
          callback: (value) => {
            return formatdate(value.ExpireTime, "YYYY-MM-DD");
          },
        },
        资源状态: {
          callback: (value) => {
            return LineStatusMap[value.Status] || value.Status;
          },
        },
        关联ID: {
          callback: (value) => {
            return value.ChannelOuterId || "-";
          },
        },
      },
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      tabledata: [],
      tabledataAll: [],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      row: {},
      title: "",
      tempdata: [],
      showeditename: false,
      curInviter: "全部",
      inviters: [],
    };
  },
  components: {
    ComTable,
    EditeName,
  },
  mounted() {
    this.getgrouplist();
    this.getsubaccountlist();
    window.editChannelOuterId = this.editChannelOuterId;
  },
  methods: {
    getgrouplist() {
      var tempdata = [];
      this.$store.dispatch("user/channelmainlist").then((res) => {
        if (res.RetCode == 0) {
          res.List.length &&
            res.List.forEach((item, index) => {
              item.PathResources &&
                item.PathResources.forEach((eitem, eindex) => {
                  eitem.CompanyName = item.CompanyName;
                  eitem.PhoneNumber = item.PhoneNumber;
                  eitem.Discount = item.PathDiscount;
                  eitem.ResourceType = "直播快";
                  eitem.UserId = item.UserId;
                  eitem.ChannelOuterId = item.ChannelOuterId;
                  eitem.Saler = item.Saler;
                  eitem.SalerId = item.SalerId;
                  tempdata.push(eitem);
                });
              item.PhoneResources &&
                item.PhoneResources.forEach((eitem, eindex) => {
                  eitem.CompanyName = item.CompanyName;
                  eitem.PhoneNumber = item.PhoneNumber;
                  eitem.Discount = item.PhoneDiscount;
                  eitem.ResourceType = "云手机";
                  eitem.UserId = item.UserId;
                  eitem.ChannelOuterId = item.ChannelOuterId;
                  eitem.Saler = item.Saler;
                  eitem.SalerId = item.SalerId;
                  tempdata.push(eitem);
                });
            });
          this.tabledataAll = tempdata;
          this.tabledata = tempdata;
        }
      });
    },
    editChannelOuterId(row) {
      this.row = row;
      this.showeditename = !this.showeditename;
    },
    closeeditename() {
      this.showeditename = !this.showeditename;
      this.getgrouplist();
    },

    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        // console.log("get user info: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          let Username;
          if (res.NickName.length == 0) {
            Username = res.Username;
          } else {
            Username = res.NickName;
          }
          let newList = {
            UserId: res.UserId,
            UserType: res.UserType,
            Username: Username,
          };
          this.inviters.push(newList);
          let allList = {
            UserId: "全部",
            UserType: "全部",
            Username: "全部",
          };
          this.inviters.unshift(allList);
        }
      });
    },
    getsubaccountlist() {
      this.$store.dispatch("sub/getsublist").then((res) => {
        if (res.RetCode == 0) {
          this.inviters = res.UserInfos;
        }
        this.getuserinfo();
      });
    },
    changeClick() {
      if (this.curInviter == "全部") {
        this.tabledata = this.tabledataAll;
      } else {
        let tmp = [];
        for (const i of this.tabledataAll) {
          if (this.curInviter == i.SalerId) {
            tmp.push(i);
          }
        }
        this.tabledata = tmp;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>